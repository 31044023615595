(function($){
  $(document).on('click', '[data-product-quick-view]', function (e) {
    e.preventDefault();

    var $this = $(this);

    $.mlsModal({
      src:  $this.attr('href'),
      data: {
        template: $this.data("product-quick-view-template"),
        ignoreWrap: $this.data("product-quick-view-ignoreWrap")
      },
      callbacks: {
        ajaxContentAdded: $.mlsSlider.thumbsSlider
      }
    });
  });
})(jQuery);