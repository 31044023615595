(function () {
  "use strict";

  var TAB_CONTAINER = "[data-intro-tab]";
  var breakPoint    = 991;

  var container = document.querySelector(TAB_CONTAINER);

  if (container) {
    var tab = new MlsTab(container, {convertBreakPoint: breakPoint, convertClass: "intro-tab--tabs"});
    tab.setDefault();
  }
})();