;(function ($) {

  $.mlsSlider = {

    getCols: function (slide, breakpoints) {

      var cols = slide ? slide.split(',') : false;

      breakpoints = breakpoints ? breakpoints.split(',') : [768, 992, 1280];

      if ($.isArray(cols)) {
        cols.shift();
        if (cols.length > 0) {
          return $.map(cols, function (value, index) {
            return {
              breakpoint: breakpoints[index],
              settings:   {
                slidesToShow: parseInt(value)
              }
            }
          });
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    getFirstCol: function (slide) {
      var cols = slide ? slide.split(',') : false;
      if (cols) {
        return parseInt(slide.split(',')[0]);
      } else {
        return 2;
      }
    },

    // instances
    thumbsSlider: function () {
      var sliders = $('[data-slider="thumbsSlider"]');

      sliders.each(function () {

        var scope       = $(this);
        var slides      = $('[data-slider-slides]', scope).attr('data-slider-slides');
        var breakpoints = $('[data-slider-breakpoints]', scope).attr('data-slider-breakpoints') || null;

        $('[data-slider-slides]', scope)
            .find('[data-slider-slide]')
            .css('float', 'left')
            .end()
            .slick({
              dots:           false,
              arrows:         true,
              adaptiveHeight: true,
              slidesToShow:   $.mlsSlider.getFirstCol(slides),
              autoplay:       false,
              autoplaySpeed:  3000,
              infinite:       false,
              swipeToSlide:   true,
              mobileFirst:    true,
              rows:           1,
              prevArrow:      $('[data-slider-arrow-left]', scope).removeClass('hidden'),
              nextArrow:      $('[data-slider-arrow-right]', scope).removeClass('hidden'),
              responsive:     $.mlsSlider.getCols(slides, breakpoints)
            });
      });
    }
  };

  // run instances
  $.mlsSlider.thumbsSlider();

})(jQuery);
