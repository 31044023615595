function MlsTab(container, config) {
  "use strict";

  // polyfill
  if (Element && !Element.prototype.matches) {
    var proto = Element.prototype;
    proto.matches = proto.matchesSelector ||
        proto.mozMatchesSelector || proto.msMatchesSelector ||
        proto.oMatchesSelector || proto.webkitMatchesSelector;
  }

  // static methods
  MlsTab.getPairs = function (container, bookmarksSelector, ref) {
    var arr           = [];
    var bookmarksList = container.querySelectorAll(bookmarksSelector);

    for (var i = 0; i < bookmarksList.length; i++) {
      var bookmark = bookmarksList[i];
      var content  = bookmark.getAttribute(ref);

      if (content == undefined) {
        console.warn("Reference for ", bookmark, " has not been set");
      } else {
        content = container.querySelector(content);

        if (content) {
          arr.push({
            bookmark: bookmark,
            content:  content
          });
        } else {
          console.warn("Has not been found related content for ", bookmark);
        }
      }
    }

    return arr;
  };

  // clear functions
  function catchClick(container, buttonSelector, handler) {

    container.addEventListener("click", function (e) {
      var target = e.target;

      // bubbling
      while (target != this) {

        // delete [] if selector has those
        //buttonSelector = buttonSelector.replace(/\[|]/g, "");

        if (target.matches(buttonSelector)) {
          e.preventDefault();

          handler(target);
          return;
        }
        target = target.parentNode;
      }

    });

  }

  function catchResize(toSmallerHandler, toBiggerHandler, bp) {

    var resizeID = null;
    window.addEventListener("resize", function () {
      clearTimeout(resizeID);
      resizeID = setTimeout(function () {
        if (window.innerWidth > bp) {
          toBiggerHandler();
          return;
        }

        toSmallerHandler();
      }, 500)
    });

  }


  // processing

  if (!container) {
    console.error("Container of tabs with this selector has not been found!");
    return false;
  }


  this._container = container;
  this._config    = {
    bookmark:            "[data-tab-button]",
    ref:                 "href",
    bookmarkActiveClass: "is-active",
    contentActiveClass:  "is-active",
    convertBreakPoint:   null,
    convertClass:        null
  };

  // read user configuration
  if (config) {
    for (var key in config) {
      this._config[key] = config[key];
    }
  }

  this._pairs = MlsTab.getPairs(this._container, this._config.bookmark, this._config.ref);


  // catch click on the tab and call handler
  catchClick(this._container, this._config.bookmark, this.toggleContent.bind(this));

  if (this._config.convertBreakPoint) {
    if (window.innerWidth > this._config.convertBreakPoint)  this.convertToTabs();
    catchResize(this.convertToAccordion.bind(this), this.convertToTabs.bind(this), this._config.convertBreakPoint);
  }


}

MlsTab.prototype.getInfo            = function () {
  "use strict";

  console.dir(this._pairs);
};
MlsTab.prototype.setActiveBookmark  = function (index) {
  "use strict";

  function clearActiveAll(arr, config) {

    arr.forEach(function (el) {
      el.bookmark.classList.remove(config.bookmarkActiveClass);
      el.content.classList.remove(config.contentActiveClass);
    })
  }

  function setActive(couple, config) {
    couple.bookmark.classList.add(config.bookmarkActiveClass);
    couple.content.classList.add(config.contentActiveClass);
  }

  clearActiveAll(this._pairs, this._config);
  setActive(this._pairs[index], this._config);
};
MlsTab.prototype.toggleContent      = function (button) {
  "use strict";

  for (var i = 0; i < this._pairs.length; i++) {
    if (button == this._pairs[i].bookmark) {
      this.setActiveBookmark(i);
      return;
    }
  }

  console.warn("Check reference for", button);
};
MlsTab.prototype.setDefault         = function () {
  "use strict";

  this.setActiveBookmark(0);
};
MlsTab.prototype.convertToAccordion = function () {
  this._pairs.forEach(function (el) {
    el.bookmark.parentNode.appendChild(el.content);
  });
  if (this._config.convertClass) this._container.classList.remove(this._config.convertClass);
};
MlsTab.prototype.convertToTabs      = function () {
  var container = this._container;
  this._pairs.forEach(function (el) {
    container.appendChild(el.content);
  });
  if (this._config.convertClass) container.classList.add(this._config.convertClass);
};


