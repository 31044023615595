/* Truncate a text if is too long */
$(document).ready(function() {
  $("[data-ellipsis]").each(function () {
    var $this = $(this);

    $this.dotdotdot({
      watch: "window",
      tolerance: 5,
      height: $this.data("ellipsis")
    });
  })
});
