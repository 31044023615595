(function ($) {

  $.mlsModal = function (options) {
    $.magnificPopup.close();
    $.magnificPopup.open({
      items: {
        src: options.src
      },
      type: options.type || 'ajax',
      ajax: {
        settings: {
          data: options.data
        }
      },
      callbacks: options.callbacks || {
        parseAjax: function (mfpResponse) {
          if (options.transferData) {
            $.mlsAjax.transferData(mfpResponse.data);
          }
        }
      },
      showCloseBtn: false,
      modal: false
    });
  }

})(jQuery);


/* Open modal window */
$(document).on('click', '[data-modal]', function (e) {
  e.preventDefault();
  var $this = $(this);

  $.mlsModal({
    src: $this.attr('href'),
    type: $this.data('modal-type'),
    data: {
      template: $this.data('modal')
    }
  });

});


/* Close modal window */
$(document).on('click', '[data-modal-close]', function (e) {
  e.preventDefault();
  $.magnificPopup.close();
});