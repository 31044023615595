;(function ($) {
  var BUTTON_ATTR = "data-dropdown-button";

  $(document).on("click", "[" + BUTTON_ATTR + "]", function (e) {
    e.preventDefault();

    var $this    = $(this);
    var $content = $($this.attr(BUTTON_ATTR));

    $content.slideToggle()
            .toggleClass("is-hidden");

  });
})(jQuery);








