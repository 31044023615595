;(function($){

    // var $productSizesModalLink = $('[data-modal-product-sizes]');
    //
    // $productSizesModalLink.on('click', function (e) {
    //     // e.stopPropagation();
    //     $modal = $('#modal-product-sizes');
    //     console.log('works');
    //     $modal.removeClass('mfp-hide');
    // });

    // $productSizesModalLink.magnificPopup({
    //     type:'inline',
    //     closeBtnInside: true,
    //     midClick: true
    // });


})(jQuery);